<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        A
        <stemble-latex content="$572\,\text{mg}$" />
        of a mixture of fluorene and benzoic acid was weighed out and subjected to an extraction and
        recrystallization. After this purification was completed the product crystals were dried and
        analyzed. The purification procedure produced
        <number-value :value="massFluorene" unit="\text{mg}" />
        of fluorene and
        <number-value :value="massBenzoicAcid" unit="\text{mg}" />
        of benzoic acid. Calculate the percent composition of each component in this purified
        mixture.
      </p>

      <calculation-input
        v-model="inputs.input1"
        prepend-text="$\text{a) Percent fluorene}:$"
        append-text="$\%$"
        class="mb-3"
        :disabled="!allowEditing"
      />

      <calculation-input
        v-model="inputs.input2"
        prepend-text="$\text{b) Percent benzoic acid}:$"
        append-text="$\%$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import NumberValue from '@/tasks/components/NumberValue.vue';

export default {
  name: 'ChemUCI51LBA1Q8',
  components: {
    NumberValue,
    CalculationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
      },
    };
  },
  computed: {
    massFluorene() {
      return this.taskState.getValueBySymbol('massFluorene').content;
    },
    massBenzoicAcid() {
      return this.taskState.getValueBySymbol('massBenzoicAcid').content;
    },
  },
};
</script>
